<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Entry -->
        <b-col
          cols="6"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.findCode')"
            />
          </div>
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="primary"
              @click="SearchData(searchQuery)"
            >{{ $t('globalActions.find') }}</b-button>
          </div>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          <b-button
            variant="primary"
            class="btn-icon invoice-edit-input"
            :to="{ name: 'apps-transactions-cashbank-rekon-add' }"
          >
            {{ $t('globalSingular.create') }}
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :filter-ignored-fields="columnToBeFilter"
      :fields="tableColumns"
      :items="getItemLists"
      responsive
      primary-key="id"
      show-empty
      :busy="isBusy"
      :empty-text="$t('globalSingular.noMatching')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <!-- Column: code -->
      <template #cell(code)="{item}">
        <b-link
          :to="{ name: `apps-transactions-cashbank-rekon-detail`, params: { id: item.id }}"
          class="font-weight-bolder"
        >
          <span class="font-weight-bolder d-block text-nowrap">
            {{ item.code }}
          </span>
        </b-link>
      </template>

      <!-- Column: Account Type -->
      <template #cell(label)="{ item }">
        <!-- {{ data.value.type_name }} -->
        <span class="font-weight-bolder d-block text-nowrap">
          {{ (item.contact) ? item.contact.label : 'Transfer' }}
        </span>
        <small class="font-italic"> {{ item.remark }} {{ $t('globalSingular.via') }} <span class="font-weight-bolder text-primary ">{{ companyActive.plan_id > 2 ? (item.office ? item.office.label : item.account.account_name) : item.account.account_name }}</span>  </small>
      </template>

      <template #cell(cashbank)="{ item }">
        <div v-if="item.transaction_type_code === 'TF' && item.is_transfer_verified" >
          <span v-if="item.amount > 0"  class="font-weight-bolder d-block text-nowrap">
            {{item.cashbankto === null ? '-': item.cashbankto.label}}
          </span>
        </div>
        <div v-else>
          <span v-if="item.amount < 0 && item.transaction_type_code === 'TF'"  class="font-weight-bolder d-block text-nowrap">
          {{item.cashbank === null ? '-': item.cashbank.label}}
          </span>
          <span v-else class="font-weight-bolder d-block text-nowrap">
            {{item.cashbank === null ? '-': item.cashbank.label}}
          </span>
        </div>
      </template>

      <!-- Column: Debit -->
      <template #cell(debit)="{ item }">
        <span :class="['font-weight-bolder', 'd-block', 'text-nowrap', 'text-primary']">
          {{ formatCurrency(item.debit, false) }}
        </span>
      </template>

      <!-- Column: Credit -->
      <template #cell(credit)="{ item }">
        <span :class="['font-weight-bolder', 'd-block', 'text-nowrap', 'text-danger']">
          {{ formatCurrency(item.credit, false) }}
        </span>
      </template>

      <!-- Column: Tags -->
      <template #cell(tags)="{ item }">
        <div v-if="(item.tags || []).length > 0" class="d-flex">
          <b-badge v-for="(tag, index) in item.tags" :key="index" pill variant="light-primary">{{ tag.name }}</b-badge>
        </div>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('globalSingular.showing') }} {{ dataMeta.from }} {{ $t('globalSingular.to') }} {{ dataMeta.to }} {{ $t('globalSingular.of') }} {{ dataMeta.of }} {{ $t('globalSingular.entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            per-page=15
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>


<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BLink,
  BPagination,
  BSpinner,
  BBadge,
  BButton
} from 'bootstrap-vue'

import { formatCurrency } from '@/utils/formatter'
import useListTablePage from '@/comp-functions/useListTablePage'
// import { ref,onMounted } from '@vue/composition-api'

// import useHttp from '@/comp-functions/useHttp'
// to access i18n
import useLocalization from '@/comp-functions/utils/useLocalization'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BPagination,
    BSpinner,
    BBadge,
    BButton
  },


  setup () {
    const { $t } = useLocalization()
    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists,
      loadPage,
      changePerPage
    } = useListTablePage({ url: 'transaction/cashbank/rekon?', urlcount: 'transaction/cashbank/rekon-count' })


    // Table Handlers
    const tableColumns = [
      { key: 'code', label: $t('globalSingular.codeTransactions') },
      { key: 'date', label: $t('globalSingular.date'), sortable: true  },
      { key: 'office.brand', label: $t('globalSingular.branch') },
      { key: 'cashbank', label: $t('globalSingular.cashBank'), sortable: true  },
      { key: 'label', label: $t('globalSingular.description') },
      { key: 'debit', label: $t('globalSingular.debit'), thClass: 'text-right', tdClass: 'text-right' },
      { key: 'credit', label: $t('globalSingular.credit'), thClass: 'text-right', tdClass: 'text-right' },
      { key: 'tags', label: $t('globalSingular.tags') },
    ]

    // Table Handlers
    const columnToBeFilter = [
      'codes',
      'date',
      'cashbank',
      'transaction_type'
    ]


    const SearchData = datasearch => {
      loadPage(`transaction/cashbank/rekon?search=${datasearch.toUpperCase()}`)
    }


    return {
      formatCurrency,
      tableColumns,
      columnToBeFilter,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      SearchData,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists,
      changePerPage,
      loadPage
    }
  },


  computed: {
    companyActive () {
      return this.$store.state.user.currentCompany
    },

    getItemLists() {
      return this.itemLists.map((item) => {
        item.debit = 0
        item.credit = 0
        item.details.map((detail) => {
          if (parseFloat(detail.amount) < 0) {
            item.credit += parseFloat(detail.amount)
          } else {
            item.debit += parseFloat(detail.amount)
          }
        })

        return item
      })
    }
  }
}
</script>
